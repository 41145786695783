<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="weatherWrap preview">
    <img :src="styleDatas.imgStyle" />
  </div>
</template>

<script>
export default {
  props: ['styleDatas'],
  data() {
    return {
      weather: require("../assets/weather.png"),
    };
  },
};
</script>

<style lang="scss" scoped>
.weatherWrap {
  width: 100%;
  height: 100%;
  user-select: none;
  img {
    width: 100%;
    max-height: 100%;
  }
}
</style>
